import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';
import * as Sentry from '@sentry/nextjs';
import cacheData from 'memory-cache';
import { ThemeProvider } from 'styled-components';
import '@themes/fonts.css';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';

import GlobalStyle from '@themes/globalStyle';
import { theme } from '@themes/main';

import { wrapper } from '@store/store';
import * as categoriesActions from '@actions/categories';
import * as menusActions from '@actions/menus';
import { selectCategoriesRoot, selectHasCategories } from '@selectors/categories';
import { selectHasAllMenus } from '@selectors/config';
import { selectBadgeCount } from '@selectors/customerInfo';
import { selectMenus } from '@selectors/menus';
import { selectLayoutPreference } from '@store/selectors/products';
import { getUserData } from '@thunks/auth';
import * as categoriesThunks from '@thunks/categories';
import * as customerInfoThunks from '@thunks/customerInfo';
import * as favoritesThunks from '@thunks/favorites';
import * as menusThunks from '@thunks/menus';
import * as productsThunks from '@thunks/products';
import * as goodiesThunks from '@thunks/goodies';
import * as vouchersThunks from '@thunks/vouchers';
import { fetchDefaultMetaInfo } from '@thunks/defaultMetaInfo';
import { fetchShopEventStatus } from '@thunks/shopEvent';
import { clearVouchers } from '@actions/vouchers';
import { selectShopEventStatus } from '@selectors/shopEvent';
import { selectBasketRehydrated, selectBasketShopEventId } from '@selectors/basket';
import { clearBasket } from '@actions/basket';
import { fetchBarcode } from '@thunks/barcode';

import { FavoritesMultiDeleteProvider } from '@routes/Favorites/FavoritesMultiDelete.context';

import { getRouteUrl } from '@utils/urls';
import { getAccessToken } from '@utils/jwt';
import request from '@utils/request';

import i18nConfig from 'next-i18next.config';
import ErrorBoundary from '@common/components/ErrorBoundary';
import { ClientBodyAttributes } from '@common/contexts/bodyAttributesContext';
import { LanguageUrlsProvider } from '@common/contexts/languageUrlsContext';
import { UserAgentProvider } from '@common/contexts/userAgentContext';
import { HistoryLengthWrapper } from '@common/contexts/historyLengthContext';
import useFavicon from '@common/hooks/useFavicon';
import { useAnalytics } from '@common/hooks/useAnalytics';
import { PRE_LOGIN, ROUTES_FOR_LOGGED_IN } from '@common/routes';
import Body from '@common/containers/Body';
import { GlobalOverlayContextProvider } from '@common/contexts/globalOverlayContext';

const MENUS_CACHE_TIME = 60 * 1000; // 60s
const CATEGORIES_CACHE_TIME = 60 * 1000; // 60s

const useAccessToken = token => {
  const router = useRouter();

  useEffect(() => {
    if (token) {
      request.setAuthorizationToken(token);
      request.setAcceptLanguage(router.locale);
    }
  }, [token, router.locale]);
};

/* eslint-disable react/prop-types */
function MyApp({ Component, pageProps, domain, router }) {
  useAccessToken(pageProps.accessToken);
  const dispatch = useDispatch();
  const layoutPreference = useSelector(selectLayoutPreference);
  const vipBadgeCount = useSelector(selectBadgeCount);
  const { shopEventId } = useSelector(selectShopEventStatus);
  const basketShopEventId = useSelector(selectBasketShopEventId);
  const isBasketStateRehydrated = useSelector(selectBasketRehydrated);

  useEffect(() => {
    // Got to handle client side only as persisted basket state is not yet available on server side
    if (isBasketStateRehydrated && shopEventId !== basketShopEventId) {
      dispatch(clearBasket());
    }
  }, [isBasketStateRehydrated, shopEventId, basketShopEventId, dispatch]);

  useAnalytics(router, pageProps?.pageData?.meta?.langUrls?.de);

  useFavicon(`/static/img/favicon/favicon${vipBadgeCount > 0 ? '-notification' : ''}.ico`);

  return (
    <ThemeProvider
      theme={{
        ...theme,
        layoutPreference,
      }}
    >
      <Head>
        <meta name="viewport"
content="width=device-width, initial-scale=1, viewport-fit=cover" />
      </Head>
      <GlobalStyle domain={domain} />
      <Body>
        <ErrorBoundary>
          <ClientBodyAttributes>
            <HistoryLengthWrapper>
              <LanguageUrlsProvider value={pageProps?.pageData?.meta?.langUrls}>
                <UserAgentProvider userAgent={pageProps.userAgent}>
                  <GlobalOverlayContextProvider>
                    <FavoritesMultiDeleteProvider>
                      <Component {...pageProps} />
                    </FavoritesMultiDeleteProvider>
                  </GlobalOverlayContextProvider>
                </UserAgentProvider>
              </LanguageUrlsProvider>
            </HistoryLengthWrapper>
          </ClientBodyAttributes>
        </ErrorBoundary>
      </Body>
    </ThemeProvider>
  );
}

MyApp.getInitialProps = wrapper.getInitialAppProps(store => async ({ Component, ctx, router }) => {
  if (Component.isolated) {
    return {
      pageProps: {
        userAgent: null,
      },
    };
  }

  const { req, res, locale } = ctx;
  const userAgent =
    (typeof window === 'undefined' ? req.headers['user-agent'] : window.navigator.userAgent) || '';

  request.setAcceptLanguage(locale);

  const accessToken = getAccessToken(req);

  if (req && accessToken) {
    request.setAuthorizationToken(accessToken);

    try {
      await store
        .dispatch(getUserData())
        .then(async () => {
          await store.dispatch(clearVouchers());
          await store.dispatch(favoritesThunks.fetchFavorites());
          await store.dispatch(vouchersThunks.fetchVouchers());
          await store.dispatch(goodiesThunks.fetchGoodies());
          await store.dispatch(customerInfoThunks.fetchCustomerBadge());
          await store.dispatch(customerInfoThunks.fetchCustomerInfo());
          await store.dispatch(fetchBarcode());
        })
        .finally(() => {
          request.removeAuthorizationToken();
        });
    } catch (error) {
      if (error?.response?.data?.redirect) {
        const redirectUrl = new URL(error.response.data.redirect);
        const redirectPath = `${redirectUrl.pathname}${redirectUrl.search}`;

        const requestPath = `/${locale}${req.url}`;

        if (requestPath !== redirectPath) {
          res.statusCode = 302;
          res.setHeader('Location', redirectPath);

          return {
            pageProps: {
              userAgent,
            },
          };
        }
      }

      Sentry.captureException(error);
      // We should redirect user to logout and remove the `access` cookie
    }
  }

  if (!accessToken && ROUTES_FOR_LOGGED_IN.includes(ctx.pathname)) {
    const targetUrl = `${getRouteUrl(locale, PRE_LOGIN)}?target=/${locale}${ctx.asPath}`;

    if (req) {
      res.statusCode = 302;
      res.setHeader('Location', targetUrl);
    } else {
      router.push(
        {
          pathname: PRE_LOGIN,
          query: {
            target: ctx.asPath,
          },
        },
        targetUrl
      );
    }

    return {
      pageProps: {
        userAgent,
      },
    };
  }

  let hasMenus = selectHasAllMenus(store.getState());
  let hasCategories = selectHasCategories(store.getState());
  let menusPromise = null;
  let categoriesPromises = [null];

  const menusCache = cacheData.get(`menus-${locale}`);
  const categoriesCache = cacheData.get(`categories-${locale}`);

  if (!hasMenus) {
    if (menusCache) {
      store.dispatch(menusActions.setMenus(menusCache));
    } else {
      menusPromise = store.dispatch(menusThunks.fetchAllMenus());
    }
  }

  if (!hasCategories) {
    if (categoriesCache) {
      store.dispatch(categoriesActions.setCategories(categoriesCache));
    } else {
      categoriesPromises = [
        store.dispatch(categoriesThunks.fetchCategories()),
        store.dispatch(categoriesThunks.fetchCategoryIcons()),
      ];
    }
  }

  const offerProductCountPromise = store.dispatch(productsThunks.fetchOfferProductsCount);
  const saleProductsCountPromise = store.dispatch(productsThunks.fetchSaleProductsCount);
  const shopEventStatusPromise = store.dispatch(fetchShopEventStatus());
  const defaultMetaInfoPromise = store.dispatch(fetchDefaultMetaInfo());

  await Promise.allSettled([
    offerProductCountPromise,
    saleProductsCountPromise,
    menusPromise,
    ...categoriesPromises,
    shopEventStatusPromise,
    defaultMetaInfoPromise,
  ]);
  hasMenus = selectHasAllMenus(store.getState());
  hasCategories = selectHasCategories(store.getState());

  if (!menusCache && hasMenus) {
    const menus = selectMenus(store.getState());

    cacheData.put(`menus-${locale}`, menus, MENUS_CACHE_TIME);
  }

  if (!categoriesCache && hasCategories) {
    const categories = selectCategoriesRoot(store.getState());

    cacheData.put(`categories-${locale}`, categories, CATEGORIES_CACHE_TIME);
  }

  return {
    pageProps: {
      ...(Component.getInitialProps ? await Component.getInitialProps({ ...ctx, store }) : {}),
      userAgent,
      accessToken,
    },
    domain: `${req ? req.headers.host : window.location.hostname}`,
    pathname: (req && req.url) || ctx.pathname,
  };
});

export default wrapper.withRedux(appWithTranslation(MyApp, i18nConfig));
